<template>
  <!--订单 待接单 创建订单-->
  <div class="xiaadan">
    <div class="title" style="margin-bottom: 0 !important;">
      <div class="title_t" v-if="name == 'addpunish'">
        <span>创建处罚订单</span>
      </div>
      <div class="title_t" v-else>
        <span>创建订单</span>
      </div>
      <div class="title_r" @click="returnPage">
        <span>返回</span>
      </div>
    </div>

    <el-form ref="form" :model="form" :rules="rules" label-width="80px">
      <el-row :gutter="15">
        <!-- 左侧部分 -->
        <el-col :span="18">
          <div ref="element">
            <!--客户信息-->
            <el-card shadow="never">
              <div class="Datalist">
                <div class="Datalist_l">
                  <div class="Datalist_icon"></div>
                  <div class="Datalist_text">
                    客户信息
                  </div>
                </div>
              </div>
              <el-col :span="12" style="margin-top: 15px;">
                <el-form-item label="客户名称:" prop="channel_id">
                  <el-input
                      v-if="userInfo.rank == 'client'"
                      :disabled="true"
                      clearable
                      v-model="userInfo.channel_name"
                      placeholder="请输入"/>
                  <el-select
                      v-else
                      @change="selectclient"
                      clearable
                      v-model="form.channel_id"
                      placeholder="请选择"
                      class="ele-fluid">
                    <el-option v-for="(item) in client" :label="item.channel_name + item.channel_type" :value="parseInt(item.id)"/>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-card>

            <!--订单信息-->
            <el-card shadow="never">
              <div class="Datalist">
                <div class="Datalist_l">
                  <div class="Datalist_icon"></div>
                  <div class="Datalist_text">
                    订单信息
                  </div>
                </div>
              </div>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="时效">
                    <el-radio v-model="radio" label="0">立即救援</el-radio>
                    <el-radio v-model="radio" label="1">预约救援</el-radio>
                  </el-form-item>
                </el-col>
                <el-col :span="16" v-if="radio == 1">
                  <el-form-item label="预约时间:" prop="appointment_time">
                    <!--<el-time-picker-->
                    <!--    value-format="HH:mm:ss"-->
                    <!--    is-range-->
                    <!--    v-model="form.appointment_time"-->
                    <!--    range-separator="至"-->
                    <!--    start-placeholder="开始时间"-->
                    <!--    end-placeholder="结束时间"-->
                    <!--    placeholder="选择时间范围">-->
                    <!--</el-time-picker>-->
                    <el-date-picker
                        value-format="yyyy-MM-dd HH:mm:ss"
                        v-model="form.appointment_time"
                        type="datetime"
                        placeholder="选择日期时间">
                    </el-date-picker>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="是否事故">
                    <!--<el-checkbox v-model="form.is_accident" :true-label="1" :false-label="0">事故</el-checkbox>-->
                    <!--<el-checkbox v-model="form.is_appointment" :true-label="1" :false-label="0">预约</el-checkbox>-->
                    <el-radio v-model="radio1" label="0">非事故</el-radio>
                    <el-radio v-model="radio1" label="1">事故</el-radio>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="订单类型:" prop="rescue_type">
                    <el-select
                        @change="selectServicesAvailable"
                        :disabled="rescue_typeshow"
                        clearable
                        v-model="form.rescue_type"
                        placeholder="请选择"
                        class="ele-fluid">
                      <el-option v-for="(item) in ServicesAvailable" :label="item.name" :value="item.items"/>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="报案号:" prop="case_number">
                    <el-input
                        clearable
                        v-model="form.case_number"
                        placeholder="请输入报案号"/>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="保单号:" prop="policy_number">
                    <el-input
                        clearable
                        v-model="form.policy_number"
                        placeholder="请输入保单号"/>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row>
                <el-col :span="8">
                  <el-form-item label="汽车类型:" prop="car_type">
                    <el-select
                        clearable
                        v-model="form.car_type"
                        placeholder="请选择"
                        class="ele-fluid">
                      <el-option
                          v-for="(item) in dict.type.automobile_type"
                          :label="item.label"
                          :value="item.value"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="品牌型号:" prop="car_brand">
                    <el-input
                        clearable
                        v-model="form.car_brand"
                        placeholder="请输入品牌型号"/>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="救援环境:" prop="rescue_environment">
                    <el-select
                        clearable
                        v-model="form.rescue_environment"
                        placeholder="请选择"
                        class="ele-fluid">
                      <el-option
                          v-for="(item) in dict.type.environment"
                          :label="item.label"
                          :value="item.value"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row>
                <el-col :span="8">
                  <el-form-item label="案件地址:" v-if="dirType == 'double'">
                    <!--<el-button @click="opensiteselection(0)">点击选择</el-button>-->
                    <div class="xuanzedidianyangshi" @click="opensiteselection(0)">
                      <span v-if="form.starting_point == undefined">请选择案件地址</span>
                      <el-tooltip class="item" effect="dark" :content="form.starting_point" placement="top-start">
                        <div class="didian">{{form.starting_point}}</div>
                      </el-tooltip>
                      <i class="el-icon-location-information"></i>
                    </div>
                  </el-form-item>
                </el-col>
                <!--救援终点只有拖车显示 其余都不显示-->
                <el-col :span="8">
                  <el-form-item label="目的地地址:">
                    <!--<el-button @click="opensiteselection(1)">点击选择</el-button>-->
                    <div class="xuanzedidianyangshi" @click="opensiteselection(1)">
                      <span v-if="form.destination == undefined">请选择目的地地址</span>
                      <el-tooltip class="item" effect="dark" :content="form.destination" placement="top-start">
                        <div class="didian">{{form.destination}}</div>
                      </el-tooltip>
                      <i class="el-icon-location-information"></i>
                    </div>
                  </el-form-item>
                </el-col>
                <el-col :span="8" v-if="dirType == 'double'">
                  <el-form-item label="辅轮:">
                    <!--<el-select-->
                    <!--    clearable-->
                    <!--    v-model="form.is_pulley"-->
                    <!--    placeholder="请选择"-->
                    <!--    class="ele-fluid">-->
                    <!--  <el-option-->
                    <!--    v-for="(item) in dict.type.jockeypulley"-->
                    <!--    :label="item.label"-->
                    <!--    :value="item.value"-->
                    <!--  />-->
                    <!--</el-select>-->
                    <el-input-number v-model="form.pulley_count"  label="" :min="0" :max="10" ></el-input-number>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row>
                <el-col :span="8">
                  <el-form-item label="客户:" prop="owners">
                    <el-input
                        clearable
                        v-model="form.owners"
                        placeholder="请输入客户姓名"/>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="客户电话:" prop="owners_phone">
                    <el-input
                        clearable
                        v-model="form.owners_phone"
                        placeholder="请输入客户电话"/>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="车牌:" prop="license_plate">
                    <el-input
                        clearable
                        v-model="form.license_plate"
                        placeholder="请输入车牌"/>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row>
                <el-col :span="8">
                  <el-form-item label="车架号:" prop="vin">
                    <el-input
                        clearable
                        v-model="form.vin"
                        placeholder="请输入车架号"/>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="免拖里程:">
                    <el-input
                        :disabled="true"
                        clearable
                        v-model="form.free_trailer"
                        placeholder="请输入免拖里程"/>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <el-form-item label="备注:">
                    <el-input
                        clearable
                        type="textarea"
                        v-model="form.remark"
                        placeholder="请输入备注信息"/>
                  </el-form-item>
                </el-col>
              </el-row>

              <div class="Datalist">
                <div class="Datalist_l">
                  <div class="Datalist_icon"></div>
                  <div class="Datalist_text">
                    自定义表单
                  </div>
                </div>
              </div>
              <el-row>
                <el-button @click="openForm()">显示自定义表单</el-button>
              </el-row>
            </el-card>

            <!--创建订单的  服务信息-->
            <el-card shadow="never" v-if="userInfo.rank != 'client'">
              <div class="Datalist">
                <div class="Datalist_l">
                  <div class="Datalist_icon"></div>
                  <div class="Datalist_text">
                    服务信息
                  </div>
                </div>
              </div>
              <el-col :span="6" style="margin-top: 15px;">
                <el-form-item label="服务商:">
                  <el-select
                      @change="selectserviceprovider"
                      clearable
                      v-model="form.facilitator_id"
                      placeholder="请选择"
                  >
                    <el-option v-for="(item) in serviceprovider" :label="item.label" :value="item.value"/>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6" style="margin-top: 15px;">
                <el-form-item label="司机:">
                  <el-select
                      :disabled="disabled"
                      clearable
                      v-model="form.driver_id"
                      placeholder="请选择"
                  >
                    <el-option v-for="(item) in driver" :label="item.label" :value="item.value"/>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-card>
          </div>
        </el-col>

        <!-- 右侧部分 -->
        <el-col :span="6">
          <el-card shadow="never">
            <AMapLoader v-if="lazyLoading === true" :msgData="msgData" v-bind:locationorigin="locationorigin" :locationdestination="locationdestination" :is="currentComponent"></AMapLoader>
          </el-card>
        </el-col>

      </el-row>

    </el-form>

    <!--按钮-->
    <div class="card_bottom">
      <el-button style="width: 200px !important;" @click="cancel">取消</el-button>
      <el-button type="warning" style="width: 200px !important; margin-left: 20px;" @click="save('form')">生成订单</el-button>
    </div>

    <el-dialog
      center
      title="选择地址"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose">
      <div>
        <!--腾讯地图选点组件-->
        <!--<div style="display: flex;">-->
        <!--  <el-input clearable v-model="keywords" placeholder="请输入位置"/>-->
        <!--  <el-button style="margin-left: 10px;" @click="onSearch">搜索</el-button>-->
        <!--</div>-->
        <!--<iframe id="mapPage" width="100%" height="700px;" frameborder="0" v-bind:src="keyUrl"></iframe>-->
        <!--自定义高德选点组件-->
        <AMapLoadersiteselection v-if="showAMapLoadersiteselection == true" :data="origin" @location="getlocation"/>
      </div>
      <div slot="footer">
        <!-- <el-button
          @click="dialogVisible = false">取消
        </el-button> -->
        <!--<el-button-->
        <!--  style="margin-left: 20px;"-->
        <!--  type="primary"-->
        <!--  @click="dialogVisible = false">确定-->
        <!--</el-button>-->
      </div>
    </el-dialog>

    <el-dialog
      center
      v-if="showcustom == true"
      title="自定义表单"
      :visible.sync="showcustom"
      width="40%"
      :before-close="handleCloseshowcustom">
      <div v-if="showcustom == true" style="margin-top: 10px;">
        <formchuangjian @formData="queryformData"/>
      </div>
    </el-dialog>

  </div>
</template>

<script>
//服务项目接口
import {getget_services_available, getself_driver} from '@/api/yunli'
import {Adddelay_join, get_driver, get_partner, getrescue_type,get_services_config} from "@/api/orderModule";
import {get_select, getfacilitator} from "@/api/custom";
// 引入配置
import setting from "@/config/config";

// 自定义高德组件
import AMapLoader from "@/components/AMapLoader/index.vue"
//引入自定义高德地图选点组件
import AMapLoadersiteselection from '@/components/AMapLoadersiteselection/index.vue'

//引入自定义表单
import formchuangjian from '../../orderModule/add/form.vue'

export default {
  // 支柱道具属性
  props: {
    name: String,
    // required: true
    // 字典数据
    dict: Object,
    data: Object,
  },
  // 组件生效
  components: {
    AMapLoader,
    AMapLoadersiteselection,
    formchuangjian
  },
  // Data数据
  data() {
    return {
      form:{
        facilitator_id:'',
        starting_point:'',
        starting_point_coord:[],
        destination:'',
        destination_coord:[],
        pulley_count:0,
      },

      // 验证规则
      rules: {
        channel_id: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        appointment_time: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        rescue_type: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        case_number:[
          { required: true, message: '请输入', trigger: 'blur' },
        ],
        policy_number:[
          { required: true, message: '请输入', trigger: 'blur' },
        ],
        car_type: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        car_brand:[
          { required: true, message: '请输入', trigger: 'blur' },
        ],
        rescue_environment: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        owners:[
          { required: true, message: '请输入', trigger: 'blur' },
        ],
        owners_phone:[
          { required: true, message: '请输入电话', trigger: 'blur' },
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: "请输入正确的手机号码",
            trigger: "blur"
          }
        ],
        license_plate:[
          { required: true, message: '请输入', trigger: 'blur' },
        ],
        vin:[
          { required: true, message: '请输入', trigger: 'blur' },
        ],
      },

      //地图组件延时加载
      lazyLoading:true,
      //高度
      msgData:0,
      currentComponent: 'AMapLoader', // 初始显示 MyComponent 组件

      //服务项目
      ServicesAvailable:[],
      // 默认不可选
      rescue_typeshow:true,

      //选择地址
      dialogVisible:false,
      //iframe
      keyUrl:'',
      // 高德
      showAMapLoadersiteselection:false,

      //用户传递的经度纬度
      locationorigin:[],
      locationdestination:[],

      //起点或者是终点
      origin:0,

      //客户列表
      client:[],
      //服务商列表
      serviceprovider:[],

      //司机列表
      driver:[],
      //默认不可选择
      disabled:true,

      // 立即救援 预约救援
      radio:'0',
      //是否事故
      radio1:'0',

      //当前登录人的信息
      userInfo:this.$store.state.admin.userInfo,

      //单双地址 single为单地址 double为双地址
      dirType:'',

      //自定义表单
      showcustom:false,

    }
  },

  // 计算属性 类似于 data 概念
  computed: {},

  // 监控 data 中的数据变化
  watch: {},

  created() {

  },

  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {
    //获取地图左侧内容的高度 传值给地图子组件
    this.msgData = this.$refs.element.offsetHeight - 20;  //减去的20是内边距距离
    //加载地图组件
    // this.lazyLoading = true;

    //获取服务项目
    // this.getFuwuxiangmu();

    // 腾讯地图组件监听
    window.addEventListener(
      'message',
      function (event) {
        // 接收位置信息，用户选择确认位置点后选点组件会触发该事件，回传用户的位置信息
        var loc = event.data;
        if (loc && loc.module === 'locationPicker') {
          // 防止其他应用也会向该页面post信息，需判断module是否为'locationPicker'
          window.parent.selectAdderss(loc);
        }
      },
      false,
    );
    window.selectAdderss = this.selectAdderss;

    // 渠道客户有默认客户直接获取救援类型 其他获取客户列表
    if(this.userInfo.rank == 'client'){
      console.log(this.userInfo)
      this.getjiuyuanleixing();
      // 救援类型默认就可选
      this.rescue_typeshow = false;
    }else {
      // 调用获取客户列表
      this.getKehu();
      // 调用获取服务商列表
      this.getFuwushang();
    }



    // 获取司机列表
    // this.getdriver();

    console.log(this.name)
    // if(this.name == 'addpunish'){
    //   this.disabled = true;
    // }else {
    //   this.disabled = false;
    // }
  },
  // 方法集合
  methods: {
    //打开自定义表单
    openForm(){
      this.showcustom = true;
    },
    //关闭自定义表单
    handleCloseshowcustom(){
      this.showcustom = false;
    },
    //接收自定义表单组件传递的值
    queryformData(value){
      console.log(value)
      // 自定义表单
      this.form.custom_field = value;
      this.showcustom = false;
    },


    // 渠道客户登录直接获取客户名称然后获取救援类型 救援类型为死值
    getjiuyuanleixing(){
      //根据客户获取救援类型
      getrescue_type(this.userInfo.id).then(res => {
        this.ServicesAvailable = res.data;
      })
    },


    //获取客户名称列表
    getKehu(){
      get_select().then(res => {
        this.client = res.data;
      })
    },

    //选择客户
    selectclient(value){
      //根据客户获取救援类型
      getrescue_type(value).then(res => {
        if(res.code == 200){
          this.ServicesAvailable = res.data;
          if(this.ServicesAvailable.length == 0){
            this.rescue_typeshow = true
            this.form.rescue_type = '';
          }else {
            this.rescue_typeshow = false;
          }
        }else {
          this.$message.error(res.msg)
        }
      })
    },

    //选择服务项目获取是单地址还是双地址
    selectServicesAvailable(value){
      let where = {
        items: value
      }
      get_services_config(this.form.channel_id,where).then(res => {
        // console.log(res);
        this.dirType = res.data.dirType;
        // 判断是单双地址重新渲染地图
        if(this.dirType == 'single'){
          this.locationorigin = [];
          //刷新地图组件
          this.reloadComponent();
        }else {
          //刷新地图组件
          this.reloadComponent();
        }
      })
    },

    //获取服务商
    getFuwushang(){
      // getfacilitator().then(res => {
      //   this.serviceprovider = res.data.list;
      // })
      get_partner().then(res => {
        this.serviceprovider = res.data;
      })
    },

    //选择服务商
    selectserviceprovider(value){
      console.log(value);
      if(value == ''){
        this.disabled = true;
      }else {
        //调用获取司机
        this.getdriver(value);
        delete this.form.driver_id
      }
    },

    // 获取列表
    // getdriver(){
    //   let where = {
    //     page:1,
    //     limit: 50
    //   }
    //   getself_driver(where).then(async (res) => {
    //     console.log(res)
    //     this.driver = res.data.list;
    //   }).catch((res) => {
    //     this.$Message.error(res.msg);
    //   })
    // },
    // 获取司机
    getdriver(id){
      get_driver(id).then(async (res) => {
        console.log(res)
        this.driver = res.data;
        this.disabled = false;
      }).catch((res) => {
        this.$Message.error(res.msg);
      })
    },

    //获取服务项目
    getFuwuxiangmu(){
      // 目前弃用
      // getget_services_available().then(res => {
      //   this.ServicesAvailable = res.data;
      // })
      //根据客户获取救援类型
      getrescue_type(value).then(res => {
        this.ServicesAvailable = res.data;
        this.rescue_typeshow = false;
      })
    },

    //点击救援起点
    opensiteselection(number){
      // 腾讯地图选点组件
      // this.dialogVisible = true;
      // // 用于判断是起点还是终点
      // this.origin = number;
      // //打开腾讯地图选址组件
      // let keys = setting.Tencentkey;
      // this.keyUrl = `https://apis.map.qq.com/tools/locpicker?type=1&key=${keys}&referer=myapp&coordtype=1&sig=ytLgIfdiuGLdxRAl1MZ7qwxPOI3W5K9k`;

      // 自定义高德组件
      // 用于判断是起点还是终点
      this.origin = number;
      this.dialogVisible = true;
      // this.showAMapLoadersiteselection = true;
      this.showAMapLoadersiteselection = false;
      this.$nextTick(() => {
        this.showAMapLoadersiteselection = true;
      })
    },

    // 选择经纬度
    selectAdderss(data) {
      console.log(data)
      // 救援起点
      if(this.origin == 0){
        // 救援起点的位置
        this.form.starting_point = data.poiaddress + data.poiname;
        // 救援起点的经度纬度
        this.form.starting_point_coord[0] = data.latlng.lat;
        this.form.starting_point_coord[1] = data.latlng.lng;
        // 隐藏选址弹窗组件
        this.dialogVisible = false;
        // 赋值给传递的起点经度纬度
        this.locationorigin[0] = data.latlng.lat;
        this.locationorigin[1] = data.latlng.lng;
        this.lazyLoading = !this.lazyLoading;
      }else {
        //救援终点位置
        this.form.destination = data.poiaddress + data.poiname;
        // 救援终点经度纬度
        this.form.destination_coord[0] = data.latlng.lat;
        this.form.destination_coord[1] = data.latlng.lng;
        // 隐藏选址弹窗组件
        this.dialogVisible = false;
        // 赋值给传递的终点经度纬度
        this.locationdestination[0] = data.latlng.lat;
        this.locationdestination[1] = data.latlng.lng;
        this.lazyLoading = !this.lazyLoading;
      }
    },

    //获取选择地址后的数据
    getlocation(value){
      console.log(value)
      // 救援起点
      if(this.origin == 0){
        // 救援起点的位置
        this.form.starting_point = value.pname + value.cityname + value.adname + value.address;
        this.form.starting_point_coord = [];
        // 救援起点的经度纬度
        this.form.starting_point_coord[0] = value.location.lng;
        this.form.starting_point_coord[1] = value.location.lat;
        // 隐藏选址弹窗组件
        // this.dialogVisible = false;
        // 赋值给传递的起点经度纬度
        this.locationorigin[0] = value.location.lng;
        this.locationorigin[1] = value.location.lat;
        // 调用重新渲染组件的方法
        this.reloadComponent();
        // this.lazyLoading = !this.lazyLoading;
      }else {
        //救援终点位置
        this.form.destination = value.pname + value.cityname + value.adname + value.address;
        this.form.destination_coord = [];
        // 救援终点经度纬度
        this.form.destination_coord[0] = value.location.lng;
        this.form.destination_coord[1] = value.location.lat;
        // 隐藏选址弹窗组件
        // this.dialogVisible = false;
        // 赋值给传递的终点经度纬度
        this.locationdestination[0] = value.location.lng;
        this.locationdestination[1] = value.location.lat;
        // 调用重新渲染组件的方法
        this.reloadComponent();
        // this.lazyLoading = !this.lazyLoading;
      }
      this.dialogVisible = false;
    },
    // 重新渲染组件
    reloadComponent() {
      this.currentComponent = null // 先把当前组件置为 null
      this.$nextTick(() => {
        this.currentComponent = AMapLoader // 然后再把要加载的组件赋值给 currentComponent
      })
    },

    //关闭
    handleClose(){
      this.dialogVisible = false;
    },

    // 返回按钮
    returnPage(){
      this.$emit('show',false) ;
    },

    //取消按钮
    cancel(){
      this.$emit('show',false) ;
    },

    //生成订单
    save(formName){
      // this.form.facilitator_id = 1;
      // this.form.driver_id = 1;

      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 立即救援 预约救援
          if(this.radio == '0'){
            this.form.is_appointment = 0;
          }else {
            this.form.is_appointment = 1;
          }

          // 是否事故
          if(this.radio1 == '0'){
            this.form.is_accident = 0
          }else {
            this.form.is_accident = 1;
          }

          //判断下是单地址还是双地址
          if(this.dirType == 'single'){
            this.form.starting_point = this.form.destination;
            this.form.starting_point_coord = this.form.destination_coord;
            delete this.form.destination
            delete this.form.destination_coord
          }

          console.log(this.form)


          // 创建处罚订单
          if(this.name == 'addpunish'){
            this.form.pid = this.data.id
            Adddelay_join(this.form).then(res => {
              if (res.code === 200) {
                this.$message.success(res.msg);
                this.$emit('show',false);
                this.$emit('done');
              } else {
                this.$message.error(res.msg);
              }
            }).catch(e => {
              this.$message.error(e.msg);
            })
          }else{
            Adddelay_join(this.form).then(res => {
              if (res.code === 200) {
                this.$message.success(res.msg);
                this.$emit('show',false);
                this.$emit('done');
              } else {
                this.$message.error(res.msg);
              }
            }).catch(e => {
              this.$message.error(e.msg);
            })
          }
        } else {
          return false;
        }
      });
    },


  },
}
</script>

<style scoped lang="scss">
.card{
  background: #ffffff;
  border-radius: 10px;
  padding: 20px;
  .card_t{
    display: flex;
    align-items: center;
  }
  .card_t_icon{
    width: 5px;
    height: 19px;
    background: #ff9b05;
  }
  .card_t_text{
    font-size: 20px;
    font-weight: 700;
    color: #202033;
    margin-left: 15px;
  }
}
.card_bottom{
  margin-top: 80px;
  text-align: center;
}

.condition{
  .condition_t{
    display: flex;
    align-items: center;
  }
  .condition_t_icon{
    width: 5px;
    height: 19px;
    background: #ff9b05;
  }
  .condition_t_text{
    font-size: 20px;
    font-weight: 700;
    color: #202033;
    margin-left: 15px;
  }


  .condition_b{
    margin-top: 20px;
    .condition_b_img{
      width: 280px;
      height: 255px;
      background-image: url("../../../assets/images/home/custom/upload_bg.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      text-align: center;
      padding-top: 50px;
      cursor: pointer;
    }
    .condition_b_img_anniu{
      width: 94px;
      height: 94px;
    }
    .condition_b_img_text{
      font-size: 16px;
      font-weight: 500;
      color: #ff9b05;
    }

    .condition_b_l{
      width: 20%;
      float: left;
    }
    .condition_b_r{
      width: 78%;
      float: right;
      margin-left: 30px;
    }

  }
  // 解决高度坍塌
  .condition_b:after{
    content: '';
    clear: both;
    display: block;
  }


}

//选择地点样式
.xuanzedidianyangshi{
  border-radius: 5px;
  -webkit-appearance: none;
  border: 1px solid var(--border-color-base);
  box-sizing: border-box;
  color: var(--color-text-regular);
  font-size: inherit;
  height: 36px;
  outline: none;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  .didian{
    overflow:hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -o-text-overflow:ellipsis;
  }
}
</style>
